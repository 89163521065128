<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-card-title class="info title white--text font-weight-bold">
            {{ $t('aboutUsPage.aboutUs') }}
          </v-card-title>
          <v-tabs v-model="activeTab">
            <v-tab key="me" @click.prevent="selectActiveTab(0)">{{ $t('languages.me') }}</v-tab>
            <v-tab key="en" @click.prevent="selectActiveTab(1)">{{ $t('languages.en') }}</v-tab>
            <v-tab key="ru" @click.prevent="selectActiveTab(2)">{{ $t('languages.ru') }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTab">
            <v-tab-item key="me">
              <validation-observer ref="formMe" v-slot="{ handleSubmit, failed }">
                <v-form lazy-validation @submit.prevent="handleSubmit(selectActiveTab)">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" hidden md="6" sm="12">
                          <v-text-field v-model="editedItem.id" :label="$t('aboutUsPage.id')"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12" sm="12">
                          <validation-provider rules="required|max:200" v-slot="{ errors }" name="title">
                            <v-text-field
                              v-model="editedItem.title"
                              name="name"
                              clearable
                              counter="200"
                              :label="$t('aboutUsPage.title')"
                              @focusout="translateTitle(editedItem.title)"
                              :error-messages="errors"></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" md="12" sm="12">
                          <validation-provider
                            :rules="editedItem.picture_path ? 'image|size:4000' : 'image|required|size:4000'"
                            v-slot="{ errors }"
                            name="image">
                            <v-file-input
                              v-model="editedItem.image"
                              accept="image/jpg, image/jpeg, image/png"
                              counter
                              :label="$t('articlesPage.image') + '(660x524)'"
                              prepend-icon="mdi-camera"
                              show-size
                              small-chips
                              :error-messages="errors"></v-file-input>
                          </validation-provider>
                        </v-col>

                        <v-col cols="2" md="2" sm="2">
                          <validation-provider rules="required|max:200" v-slot="{ errors }" name="field_one">
                            <v-text-field
                              v-model="editedItem.field_one"
                              name="name"
                              clearable
                              counter="200"
                              :label="$t('aboutUsPage.field_one')"
                              :error-messages="errors"></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="2" md="2" sm="2">
                          <validation-provider rules="required|max:200" v-slot="{ errors }" name="field_two">
                            <v-text-field
                              v-model="editedItem.field_two"
                              name="name"
                              clearable
                              counter="200"
                              :label="$t('aboutUsPage.field_two')"
                              :error-messages="errors"></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="2" md="2" sm="2">
                          <validation-provider rules="required|max:200" v-slot="{ errors }" name="field_three">
                            <v-text-field
                              v-model="editedItem.field_three"
                              name="name"
                              clearable
                              counter="200"
                              :label="$t('aboutUsPage.field_three')"
                              :error-messages="errors"></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="2" md="2" sm="2">
                          <validation-provider rules="required|max:200" v-slot="{ errors }" name="field_four">
                            <v-text-field
                              v-model="editedItem.field_four"
                              name="name"
                              clearable
                              counter="200"
                              :label="$t('aboutUsPage.field_four')"
                              :error-messages="errors"></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="2" md="2" sm="2">
                          <validation-provider rules="required|max:200" v-slot="{ errors }" name="field_five">
                            <v-text-field
                              v-model="editedItem.field_five"
                              name="name"
                              clearable
                              counter="200"
                              :label="$t('aboutUsPage.field_five')"
                              :error-messages="errors"></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" md="12" sm="12">
                          <v-label>{{ $t('aboutUsPage.body') }}</v-label>
                          <validation-provider rules="required" v-slot="errors" name="body">
                            <vue-editor
                              v-model="editedItem.body"
                              :placeholder="$t('aboutUsPage.body')"
                              @blur="translateDescription(editedItem.body)"></vue-editor>
                            <div v-if="errors.errors[0]" v class="v-text-field__details">
                              <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                  <div class="v-messages__message">
                                    {{ errors.errors[0] }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <button-submit :failed="failed" :loading="loading" buttonText="buttons.next"></button-submit>
                  </v-card-actions>
                </v-form>
              </validation-observer>
            </v-tab-item>

            <v-tab-item key="en">
              <validation-observer ref="formEn" v-slot="{ handleSubmit, failed }">
                <v-form lazy-validation @submit.prevent="handleSubmit(selectActiveTab)">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" hidden md="6" sm="12">
                          <v-text-field v-model="editedItem.id" :label="$t('aboutUsPage.id')"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12" sm="12">
                          <validation-provider rules="required|max:200" v-slot="{ errors }" name="title">
                            <v-text-field
                              v-model="editedItem.title_en"
                              name="name"
                              clearable
                              counter="200"
                              :label="$t('aboutUsPage.title')"
                              :error-messages="errors"></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" md="12" sm="12">
                          <validation-provider rules="image|size:4000" v-slot="{ errors }" name="image">
                            <v-file-input
                              v-model="editedItem.image"
                              accept="image/jpg, image/jpeg, image/png"
                              counter
                              :label="$t('articlesPage.image') + '(660x524)'"
                              prepend-icon="mdi-camera"
                              show-size
                              small-chips
                              :error-messages="errors"></v-file-input>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" md="12" sm="12">
                          <v-label>{{ $t('aboutUsPage.body') }}</v-label>
                          <validation-provider rules="required" v-slot="errors" name="body">
                            <vue-editor v-model="editedItem.body_en" :placeholder="$t('aboutUsPage.body')"></vue-editor>
                            <div v-if="errors.errors[0]" v class="v-text-field__details">
                              <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                  <div class="v-messages__message">
                                    {{ errors.errors[0] }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <button-submit :failed="failed" :loading="loading" buttonText="buttons.next"></button-submit>
                  </v-card-actions>
                </v-form>
              </validation-observer>
            </v-tab-item>

            <v-tab-item key="ru">
              <validation-observer ref="formRu" v-slot="{ handleSubmit, failed }">
                <v-form lazy-validation @submit.prevent="handleSubmit(selectActiveTab)">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" hidden md="6" sm="12">
                          <v-text-field v-model="editedItem.id" :label="$t('aboutUsPage.id')"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12" sm="12">
                          <validation-provider rules="required|max:200" v-slot="{ errors }" name="title">
                            <v-text-field
                              v-model="editedItem.title_ru"
                              name="name"
                              clearable
                              counter="200"
                              :label="$t('aboutUsPage.title')"
                              :error-messages="errors"></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" md="12" sm="12">
                          <validation-provider rules="image|size:4000" v-slot="{ errors }" name="image">
                            <v-file-input
                              v-model="editedItem.image"
                              accept="image/jpg, image/jpeg, image/png"
                              counter
                              :label="$t('articlesPage.image') + '(660x524)'"
                              prepend-icon="mdi-camera"
                              show-size
                              small-chips
                              :error-messages="errors"></v-file-input>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" md="12" sm="12">
                          <v-label>{{ $t('aboutUsPage.body') }}</v-label>
                          <validation-provider rules="required" v-slot="errors" name="body">
                            <vue-editor v-model="editedItem.body_ru" :placeholder="$t('aboutUsPage.body')"></vue-editor>
                            <div v-if="errors.errors[0]" v class="v-text-field__details">
                              <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                  <div class="v-messages__message">
                                    {{ errors.errors[0] }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                  </v-card-actions>
                </v-form>
              </validation-observer>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>

      <v-snackbar v-model="snackbar.active" :color="snackbar.color" :timeout="2000" bottom>
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar.active = false">
            {{ $t('snackbar.close') }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { VueEditor, Quill } from 'vue2-editor';
import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize-vue';

Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);

export default {
  name: 'aboutUsPage',
  components: { ButtonSubmit, VueEditor },
  data: () => ({
    aboutUs: [],
    formValid: true,
    editedItem: {},
    enableSave: true,
    loading: false,
    snackbar: {
      active: false,
      color: '',
      text: '',
    },
    activeTab: null,
  }),
  created() {
    this.loadAboutUs();
  },
  computed: {},
  methods: {
    async loadAboutUs() {
      await this.$store.dispatch('aboutUs/getAboutUs').then((res) => {
        this.editItem(res.data[0]);
      });
    },

    editItem(item) {
      this.editedItem = Object.assign(
        {},
        {
          ...item,
          title: JSON.parse(item.title).me,
          title_en: JSON.parse(item.title).en,
          title_ru: JSON.parse(item.title).ru,
          body: JSON.parse(item.body).me,
          body_en: JSON.parse(item.body).en,
          body_ru: JSON.parse(item.body).ru,
        }
      );
    },

    async selectActiveTab(tab = null) {
      let previousTab = this.activeTab;
      const isValid = await this.validateCurrentTab();
      if (isValid) {
        if (tab !== null) {
          if (previousTab == 0) {
            this.translateDescription(this.editedItem.body);
          }
          this.activeTab = tab; // Change to the selected tab if current is valid
        } else {
          if (previousTab < 2) {
            if (previousTab == 0) {
              this.translateDescription(this.editedItem.body);
            }
            this.activeTab += 1; // Move to the next tab if current tab is validated
          } else {
            this.save(); // Save if the last tab is validated
          }
        }
      } else {
        this.activeTab = previousTab;
      }
    },

    async validateCurrentTab() {
      switch (this.activeTab) {
        case 0:
          return await this.$refs.formMe.validate();
        case 1:
          return await this.$refs.formEn.validate();
        case 2:
          return await this.$refs.formRu.validate();
        default:
          return false;
      }
    },

    createFormData() {
      let formData = new FormData();

      if (this.editedItem.id) {
        formData.append('id', this.editedItem.id);
      } else {
        formData.append('id', '');
      }

      formData.append('field_one', this.editedItem.field_one);
      formData.append('field_two', this.editedItem.field_two);
      formData.append('field_three', this.editedItem.field_three);
      formData.append('field_four', this.editedItem.field_four);
      formData.append('field_five', this.editedItem.field_five);

      formData.append(
        'title',
        JSON.stringify({
          me: this.editedItem.title,
          en: this.editedItem.title_en,
          ru: this.editedItem.title_ru,
        })
      );
      formData.append(
        'body',
        JSON.stringify({
          me: this.editedItem.body,
          en: this.editedItem.body_en,
          ru: this.editedItem.body_ru,
        })
      );

      formData.append('image', this.editedItem.image ? this.editedItem.image : '');

      return formData;
    },

    async save() {
      this.enableSave = false;
      this.loading = true;

      let formData = this.createFormData();

      await this.$store
        .dispatch('aboutUs/saveAboutUs', formData)
        .then(() => {
          this.showSnackbar(i18n.t('snackbar.savedSuccessfully'), 'green');
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.showSnackbar(e, 'red'));
          } else {
            this.showSnackbar(i18n.t('snackbar.anErrorOccured'), 'red');
          }
        })
        .finally(() => {
          this.enableSave = true;
          this.loading = false;
        });
    },

    showSnackbar(text, color) {
      this.snackbar.active = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
    },

    async translateTitle(text) {
      this.editedItem.title_en = await this.translateText(text, 'en', 'sr');
      this.editedItem.title_ru = await this.translateText(text, 'ru', 'sr');
    },

    async translateDescription(text) {
      this.editedItem.body_en = await this.translateText(text, 'en', 'sr');
      this.editedItem.body_ru = await this.translateText(text, 'ru', 'sr');
    },

    async translateText(query, targetLang, sourceLang) {
      var data = {
        q: query,
        target: targetLang,
        source: sourceLang,
      };

      try {
        const response = await this.$store.dispatch('googleTranslate/translateText', data);
        const jsonData = await response.json(); // Assuming the action returns a response compatible with `.json()`
        const translatedText = jsonData.data.translations[0].translatedText;

        return translatedText; // This will be returned to the caller of translateText
      } catch (error) {
        this.showSnackbar(i18n.t('snackbar.anErrorOccuredWithTranslation'), 'red');
      }
    },
  },

  watch: {},
};
</script>

<style>
.stats {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.cell-style {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>
